import React from "react"
import Uninterrupted from "../images/logo.uninterrupted.svg"
import Tangerine from "../images/logo.tangerine.svg"
import OntarioCreates from "../images/logo.ontario-creates.svg"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from "gatsby"

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <footer className="c-footer">
                <div className="o-wrapper o-content o-container o-content-container o-content-container--large">
                    <div className="c-footer__logos o-child-spacer">
                        <a href="https://canada.uninterrupted.com/" rel="noreferrer" target="_blank">
                            <img className="c-footer__logo" src={Uninterrupted} alt="UNINTERRUPTED Canada"></img>
                        </a>
                        <a href="https://www.tangerine.ca/" rel="noreferrer" target="_blank">
                            <img className="c-footer__logo" src={Tangerine} alt="Tangerine"></img>
                        </a>
                        <a href="https://ontariocreates.ca/" rel="noreferrer" target="_blank">
                            <img className="c-footer__logo" src={OntarioCreates} alt="Ontario Creates"></img>
                        </a>
                    </div>
                    {this.props.pathname.includes('/videos/') || this.props.pathname.includes('/blog/') || this.props.pathname.includes('/article/') ? (
                        <div className="c-footer__legal">
                            <div className="o-content-container">
                                {documentToReactComponents(JSON.parse(this.props.global.legalText.raw))}
                            </div>
                        </div>
                    ) : ''}
                    <div className="o-child-spacer u-text-center">
                        {this.props.global.footerNavigation.map((item, index) => {
                            return (
                                <Link key={index} className="c-navigation__link" to={item.link} target={item.openInNewTab ? '_blank' : '_self'}>{item.title}</Link>
                            )
                        })}
                    </div>
                </div>
                <div className="c-footer__bg"></div>
            </footer>
        );
    }
};