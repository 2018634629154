import React from "react"
import Uninterrupted from "../images/logo.uninterrupted.svg"
import { Link } from "gatsby"

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hamburgerOpen: false
        };
        this.toggleHamburger = this.toggleHamburger.bind(this);
    }

    toggleHamburger() {
        this.setState({ hamburgerOpen: !this.state.hamburgerOpen });
    }

    render() {
        return (
            <header className="c-header">
                <Link className="c-header__logo" to="/">
                    <img src={Uninterrupted} alt={this.props.global.title} />
                </Link>
                <div role="button" className={`c-header__hamburger ${this.state.hamburgerOpen ? 'c-header__hamburger--open' : ''}`} onClick={this.toggleHamburger} onKeyPress={this.toggleHamburger} tabIndex="0">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div className={`c-navigation o-wrapper ${this.state.hamburgerOpen ? 'c-navigation--open' : ''}`}>
                    <ul className="c-navigation__items o-child-spacer">
                        {this.props.global.navigation.map((item, index) => {
                            return (
                                <li key={index} className="c-navigation__item">
                                    <Link activeClassName="c-navigation__link--active" className={`c-navigation__link ${(this.props.pathname.includes('blog') && item.link.includes('blog')) || (this.props.pathname.includes('video') && item.link.includes('video')) ? 'c-navigation__link--active' : ''}`} to={item.link} target={item.openInNewTab ? '_blank' : '_self'}>{item.title}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </header>
        );
    }
};